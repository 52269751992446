var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"modelsCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"","opacity":"0.5","spinner-small":"","spinner-variant":"primary"}},[_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"ListIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("Network specifications")])],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Vlan Name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"name","rules":_vm.form.is_default ? 'max:255' : 'required|max:255'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.form.is_default,"state":errors.length > 0 ? false:null,"placeholder":"Enter vlan name","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Vlan ID No","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Vlan ID","vid":"vlan_id","rules":_vm.form.is_default ? 'max:255' : 'required|max:255|between:2,4094'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.form.is_default,"state":errors.length > 0 ? false:null,"placeholder":"Enter vlan ID number","autocomplete":"off"},model:{value:(_vm.form.vlan_id),callback:function ($$v) {_vm.$set(_vm.form, "vlan_id", $$v)},expression:"form.vlan_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Interface Address","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Interface Address","vid":"interface_ip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Enter interface address","autocomplete":"off","onchange":function (value) { return _vm.form.interface_ip = value; },"model":_vm.form.interface_ip,"inputs-id":"interface_ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Subnet Mask","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Subnet Mask","vid":"subnet_mask"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Enter subnet address","autocomplete":"off","onchange":function (value) { return _vm.form.subnet_mask = value; },"model":_vm.form.subnet_mask,"inputs-id":"subnet_mask"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Comment","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"comment","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.form.is_default,"state":errors.length > 0 ? false:null,"placeholder":"Enter your comment","autocomplete":"off"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])]),_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"","opacity":"0.5","spinner-small":"","spinner-variant":"primary"}},[_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"GlobeIcon","width":"24","height":"24"}}),_c('h2',[_vm._v(_vm._s(_vm.form.is_default ? 'Vlan Ports' : 'Ports'))])],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Tagged ports","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"tagged_ports"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{staticClass:"multiselect with-remove",attrs:{"options":_vm.portsOption,"disabled":_vm.form.is_default,"multiple":"","placeholder":"Select tagged ports","searchable":false,"label":"fullName"},on:{"select":_vm.onSelectPort,"remove":function (prop) { return _vm.onDeselectPort(prop, 'tagged'); }},model:{value:(_vm.form.tagged_ports),callback:function ($$v) {_vm.$set(_vm.form, "tagged_ports", $$v)},expression:"form.tagged_ports"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Untagged ports","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"untagged_ports"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{staticClass:"multiselect with-remove",attrs:{"options":_vm.portsOption,"disabled":_vm.form.is_default,"multiple":"","placeholder":"Select tagged ports","searchable":false,"label":"fullName"},on:{"select":_vm.onSelectPort,"remove":function (prop) { return _vm.onDeselectPort(prop, 'untagged'); }},model:{value:(_vm.form.untagged_ports),callback:function ($$v) {_vm.$set(_vm.form, "untagged_ports", $$v)},expression:"form.untagged_ports"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.form.is_default)?_c('p',{staticClass:"lan-error-message"},[_vm._v(" Edit is not allowed for dynamic ports ")]):_vm._e()],1)]),_c('b-col',{staticClass:"text-right buttons-container__left",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","ok-variant":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('b-button-request',{attrs:{"text":"Update","request":_vm.validationForm}})],1)],1)],1)],1)],1),_c('b-modal',{ref:"savedLan",attrs:{"id":"savedLan","title":"Success!","size":"md","ok-title":"Ok","ok-only":"","centered":""},on:{"hidden":_vm.goBack,"ok":_vm.goBack}},[_c('p',[_vm._v("Your LAN is successfully updated.")]),_c('p',[_vm._v("Please note: you might want to update your"+_vm._s(' ')+" "),_c('b-link',{attrs:{"to":("/site/" + _vm.siteId + "/hardware/index?tab=2&subTab=dhcp_table")}},[_vm._v(" DHCP servers ")]),_vm._v(" as well")],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }